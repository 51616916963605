import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { useEffect } from 'react';

gsap.registerPlugin(MotionPathPlugin);

const GSAPAnimation = () => {
    useEffect(() => {
        gsap.set('.rw-mainflow .rw-mainflow__wave rect', { strokeOpacity: 0 });
        gsap.to('.rw-mainflow .rw-mainflow__wave rect', {
            keyframes: {
                strokeOpacity: [0, 1, 0],
                ease: 'power1.inOut',
            },
            duration: 1,
            stagger: 0.16,
            repeat: -1,
        });

        const tl = gsap.timeline({ repeat: -1 });
        gsap.set('.rw-mainflow .rw-mainflow__circuit-lines path, .rw-mainflow .rw-mainflow__wave-lines path', {
            strokeDasharray: 'none', // Make lines solid
            strokeWidth: 1.15,
        });

        tl.to('.rw-mainflow .rw-mainflow__circuit-lines path', {
            duration: 4,
            strokeDashoffset: 0, // No offset needed for solid lines
            ease: 'power0.out',
        });

        tl.to('.rw-mainflow .rw-mainflow__wave-lines path', {
            duration: 4,
            strokeDashoffset: 0, // No offset needed for solid lines
            ease: 'power0.out',
        }, '<');

        const circles = [
            { selector: '.circle-2', path: '.path-1', duration: 7 },
            { selector: '.circle-11', path: '.path-1', duration: 5 },
            { selector: '.circle-3', path: '.path-4', duration: 6 },
            { selector: '.circle-1', path: '.path-4', duration: 5 },
            { selector: '.circle-4', path: '.path-4', duration: 9 },
            { selector: '.circle-10', path: '.path-3', duration: 5 },
            { selector: '.circle-9', path: '.path-3', duration: 14 },
            { selector: '.circle-5', path: '.path-3', duration: 3 },
            { selector: '.circle-6', path: '.path-3', duration: 5 },
            { selector: '.circle-12', path: '.path-2', duration: 6 },
            { selector: '.circle-8', path: '.path-2', duration: 12 },
            { selector: '.circle-7', path: '.path-2', duration: 8 },
            { selector: '.circle-13', path: '.path-5', duration: 5 },
            { selector: '.circle-14', path: '.path-5', duration: 6 },
        ];

        circles.forEach(({ selector, path, duration }) => {
            gsap.to(`.rw-mainflow .rw-mainflow__wave-lines ${selector}`, {
                motionPath: {
                    path: `.rw-mainflow .rw-mainflow__wave-lines ${path}`,
                    align: `.rw-mainflow .rw-mainflow__wave-lines ${path}`,
                    alignOrigin: [0.5, 0.5],
                    autoRotate: true,
                    start: 1,
                    end:0
                },
                repeat: -1,
                duration,
                ease: 'power1.inOut',
            });
        });

        const circuitCircles = [
            { selector: '.circle-1', path: '.path-2', duration: 3 },
            { selector: '.circle-2', path: '.path-1', duration: 6 },
            { selector: '.circle-3', path: '.path-4', duration: 8 },
            { selector: '.circle-4', path: '.path-4', duration: 10 },
            { selector: '.circle-5', path: '.path-2', duration: 5 },
        ];

        circuitCircles.forEach(({ selector, path, duration }) => {
            gsap.from(`.rw-mainflow .rw-mainflow__circuit-lines ${selector}`, {
                motionPath: {
                    path: `.rw-mainflow .rw-mainflow__circuit-lines ${path}`,
                    align: `.rw-mainflow .rw-mainflow__circuit-lines ${path}`,
                    alignOrigin: [0.5, 0.5],
                    autoRotate: true,
                    start: 1,
                    end: 0
                },
                repeat: -1,
                duration,
                ease: 'power1.inOut',
            });
        });
    }, []);

    return (
        <div class="rw-mainflow">
            <svg class="d-block img-fluid" width="300" height="333" viewBox="0 0 300 333">
                {/* SVG content here */}
                

                    <defs>
                        <linearGradient id="grad-1" x1="0.96" y1="0.571" x2="0.055" y2="0.635" gradientUnits="objectBoundingBox">
                            <stop offset="0%" stop-color="#8d50ff" stop-opacity="0">
                                <animate attributeName="stop-color" values="#8d50ff; #8957ff; #d971ff; #3ebbfd; #55ffeb; #8d50ff" dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="0.227" stop-color="#8957ff">
                                <animate attributeName="stop-color" values="#8957ff; #d971ff; #3ebbfd; #55ffeb; #8d50ff" dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="0.514" stop-color="#d971ff">
                                <animate attributeName="stop-color" values="#d971ff; #3ebbfd; #55ffeb; #8d50ff; #8957ff; #d971ff" dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="0.768" stop-color="#3ebbfd">
                                <animate attributeName="stop-color" values="#3ebbfd; #55ffeb; #8d50ff; #8957ff; #d971ff; #3ebbfd" dur="4s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="100%" stop-color="#55ffeb" stop-opacity="0"><animate attributeName="stop-color" values="#55ffeb; #8d50ff; #8957ff; #d971ff; #3ebbfd; #55ffeb;" dur="4s" repeatCount="indefinite"></animate></stop>
                        </linearGradient>
                        <linearGradient id="linear-gradient" x1="-0.167" y1="-0.129" x2="1" y2="1.073" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#7c98ff"></stop>
                            <stop offset="1" stop-color="#182a8e"></stop>
                        </linearGradient>
                        <filter id="Rectangle_386" x="518.193" y="20.912" width="66.681" height="66.681" filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha"></feOffset>
                            <feGaussianBlur stdDeviation="3" result="blur"></feGaussianBlur>
                            <feFlood flood-opacity="0.161"></feFlood>
                            <feComposite operator="in" in2="blur"></feComposite>
                            <feComposite in="SourceGraphic"></feComposite>
                        </filter>
                        <linearGradient id="linear-gradient-2" x1="0.235" y1="-0.125" x2="1.473" y2="1.758" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#7390f5"></stop>
                            <stop offset="1" stop-color="#0a2ca0"></stop>
                        </linearGradient>
                        <clipPath id="clip-path">
                            <circle id="Ellipse_327" data-name="Ellipse 327" cx="15" cy="15" r="15" transform="translate(-245.76 -1705.233)" fill="none"></circle>
                        </clipPath>
                    </defs>
                    <g id="Group_3520" data-name="Group 3520" transform="translate(7600.5 -9881.5)">
                        <g id="Group_3510" class="rw-mainflow__wave-lines" data-name="Group 3510" transform="translate(0 -8.631)">
                            <path class="path-1" id="Path_5249" data-name="Path 5249" d="M-67-1467.5H14.729c15.408,0,30.283-7.041,41.824-19.8l5.79-6.4c16.79-18.559,38.432-28.8,60.849-28.8H185" transform="translate(-7564.241 11597.233)" fill="none" stroke="url(#grad-1)" stroke-width="1" style={{ strokeWidth: "1px", strokeDasharray: "4, 5", strokeDashoffset: "-65px" }}></path>
                            <line class="line-1" id="Line_60" data-name="Line 60" x2="252" transform="translate(-7631.241 10058.733)" fill="none" stroke="url(#grad-1)" stroke-width="1"></line>
                            <path class="path-2" id="Path_5250" data-name="Path 5250" d="M-67-1612.5H14.729c15.408,0,30.283,7.041,41.824,19.8l5.79,6.4c16.79,18.559,38.432,28.8,60.849,28.8H185" transform="translate(-7564.241 11597.233)" fill="none" stroke="url(#grad-1)" stroke-width="1" style={{ strokeWidth: "1px", strokeDasharray: "4, 5", strokeDashoffset: "-65px" }}></path>
                            <path class="path-3" id="Path_5251" data-name="Path 5251" d="M-67-1574H14.729c15.408,0,30.283,3.649,41.824,10.259l5.79,3.316c16.79,9.617,38.432,14.925,60.849,14.925H185" transform="translate(-7564.241 11597.233)" fill="none" stroke="url(#grad-1)" stroke-width="1" style={{ strokeWidth: "1px", strokeDasharray: "4, 5", strokeDashoffset: "-65px" }}></path>
                            <path class="path-4" id="Path_5252" data-name="Path 5252" d="M-67-1503.5H14.729c15.408,0,30.283-3.649,41.824-10.259l5.79-3.316c16.79-9.617,38.432-14.925,60.849-14.925H185" transform="translate(-7564.241 11597.233)" fill="none" stroke="url(#grad-1)" stroke-width="1" style={{ strokeWidth: "1px", strokeDasharray: "4, 5", strokeDashoffset: "-65px" }}></path>
                            <path class="path-5" id="Path_5253" data-name="Path 5253" d="M-67-1500l5.79,3.316H185" transform="translate(-7564.241 11555.233)" fill="none" stroke="url(#grad-1)" stroke-width="1" style={{strokeWidth: "1px", strokeDasharray: "none", strokeDashoffset: "0px"}}></path>
                            <circle class="circle-1" id="Ellipse_306" data-name="Ellipse 306" cx="3.363" cy="3.363" r="3.363" transform="matrix(1,0,0,1,-7383.40488,10061.86989)" fill="#bf74ff" data-svg-origin="3.36299991607666 3.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-2" id="Ellipse_317" data-name="Ellipse 317" cx="2.363" cy="2.363" r="2.363" transform="matrix(1,0,0,1,-7626.52037,10127.37015)" fill="#bf74ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-3" id="Ellipse_314" data-name="Ellipse 314" cx="2.363" cy="2.363" r="2.363" transform="matrix(0.94415,-0.32952,0.32952,0.94415,-7522.57196,10087.38758)" fill="#8474ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-4" id="Ellipse_308" data-name="Ellipse 308" cx="2.363" cy="2.363" r="2.363" transform="matrix(0.99977,-0.02166,0.02166,0.99977,-7446.53861,10062.95497)" fill="#bf74ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-5" id="Ellipse_316" data-name="Ellipse 316" cx="2.363" cy="2.363" r="2.363" transform="matrix(1,0,0,1,-7383.82403,10049.37081)" fill="#bf74ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-6" id="Ellipse_313" data-name="Ellipse 313" cx="2.363" cy="2.363" r="2.363" transform="matrix(1,0,0,1,-7382.40517,10049.37095)" fill="#8474ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-7" id="Ellipse_312" data-name="Ellipse 312" cx="3.363" cy="3.363" r="3.363" transform="matrix(1,0,0,1,-7394.65868,10036.37112)" fill="#bf74ff" data-svg-origin="3.36299991607666 3.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-8" id="Ellipse_318" data-name="Ellipse 318" cx="2.363" cy="2.363" r="2.363" transform="matrix(1,0,0,1,-7604.23313,9982.37018)" fill="#8474ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-9" id="Ellipse_315" data-name="Ellipse 315" cx="3.363" cy="3.363" r="3.363" transform="matrix(1,0,0,1,-7632.91271,10019.87047)" fill="#bf74ff" data-svg-origin="3.36299991607666 3.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-10" id="Ellipse_310" data-name="Ellipse 310" cx="2.363" cy="2.363" r="2.363" transform="matrix(1,0,0,1,-7382.40511,10049.37072)" fill="#8474ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-11" id="Ellipse_309" data-name="Ellipse 309" cx="3.363" cy="3.363" r="3.363" transform="matrix(1,0,0,1,-7383.44248,10071.36991)" fill="#8474ff" data-svg-origin="3.36299991607666 3.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-12" id="Ellipse_305" data-name="Ellipse 305" cx="5.363" cy="5.363" r="5.363" transform="matrix(0.79246,0.60993,-0.60993,0.79246,-7517.39038,9988.92838)" fill="#889aff" data-svg-origin="5.36299991607666 5.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-13" id="Ellipse_310" data-name="Ellipse 310" cx="2.363" cy="2.363" r="2.363" transform="matrix(1,0,0,1,-7382.40511,10049.37072)" fill="#8474ff" data-svg-origin="2.36299991607666 2.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>
                            <circle class="circle-14" id="Ellipse_309" data-name="Ellipse 309" cx="3.363" cy="3.363" r="3.363" transform="matrix(1,0,0,1,-7383.44248,10071.36991)" fill="#8474ff" data-svg-origin="3.36299991607666 3.36299991607666" style={{ translate: "none", rotate: "none", scale: "none", transformOrigin: "0px 0px" }}></circle>

                        </g>


                    </g>

            </svg>


        </div>
    )
}

export default GSAPAnimation;
