import React from "react";
import styles from "../styles/ThankYouSection.module.css";
import thankYouImage from "../assets/thankyou.svg";
import box1 from "../assets/box1.svg";

const ThankYouPage = () => {
  return (
    <div className={styles.thankYouContainer}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <img src={box1} alt="Box" className={styles.boxImage} />
      <div className={styles.messageContainer}>
        <img
          src={thankYouImage}
          alt="Thank You"
          className={styles.thankYouImage}
        />
        <h1 className={styles.text}>Thank You</h1>
        <p className={styles.desc}>
          Your submission has been received. <br />
          We'll reach out shortly.
        </p>
      </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
