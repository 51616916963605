import React, { useEffect, useRef, useMemo, useState } from "react";
import img1 from "../assets/img1.png";
import "./style.css";
import ContactSection1 from "../ContactSectionBE2";
import cubebottom from "../assets/cubebottom.png";
import diamonds from "../assets/diamonds.png";
import icon1 from "../assets/icon1.svg";
import icon2 from "../assets/icon2.svg";
import icon3 from "../assets/icon3.svg";
import icon4 from "../assets/icon4.svg";
import icon5 from "../assets/icon5.svg";
import icon6 from "../assets/icon6.png";
import icon7 from "../assets/icon7.png";
import icon8 from "../assets/icon8.png";
import pink_purple_topleft from "../assets/pink_purple_topleft.png";
import floating_iconn from "../assets/floating_icon.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-number-input/style.css";
import Finall from "../assets/Final.png";
import stage11 from "../assets/stage1.png";
import stage22 from "../assets/stage2.png";
import stage33 from "../assets/stage3.png";
import stage44 from "../assets/stage4.png";
import stage55 from "../assets/stage5.png";
import stage66 from "../assets/stage6.png";
import stage77 from "../assets/stage7.png";
import home1 from "../assets/home1.svg";
import home2 from "../assets/home2.svg";
import home3 from "../assets/home3.svg";
import home4 from "../assets/home4.svg";
import whitee from "../assets/white.png";
import Mobile11 from "../assets/Mobile1.svg";
import Mobile22 from "../assets/Mobile2.svg";
import Mobile33 from "../assets/Mobile3.svg";
import Mobile44 from "../assets/Mobile4.svg";
import "./style.css";
import { Client, Databases, ID } from "appwrite";
import GSAPAnimation from "./GsapAnimation";
import MobileStage11 from "../assets/MobileStage1.svg";
import MobileStage22 from "../assets/MobileStage2.svg";
import MobileStage33 from "../assets/MobileStage3.svg";
import MobileStage44 from "../assets/MobileStage4.svg";
import MobileStage55 from "../assets/MobileStage5.svg";
import MobileStage66 from "../assets/MobileStage6.svg";
import MobileStage77 from "../assets/MobileStage7.svg";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import journey from "../assets/ai_journey.mp4";

const HomepageBE = () => {
  const [animate, setAnimate] = useState(false);

  const paragraphs = [
    "Many companies struggle to understand their readiness for AI adoption and their overall digital maturity.",
    "Did you know that over 77% of companies globally are actively exploring how to incorporate AI into their business?",
    "While there's immense enthusiasm for AI, many companies lack a clear understanding of their AI readiness.",
  ];

  const [currentParagraph, setCurrentParagraph] = useState(0);
  const [previousParagraph, setPreviousParagraph] = useState(null);
  const scrollContainerrRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setPreviousParagraph(currentParagraph);
      setCurrentParagraph((prev) => (prev + 1) % paragraphs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentParagraph]);

  const rectangles = [
    {
      text: "Does your leadership have a clear vision for AI, and are they committed to its success?",
      imgSrc: icon1,
    },
    {
      text: "Are your business goals aligned with potential AI applications?",
      imgSrc: icon2,
    },
    {
      text: "Do you have the necessary data infrastructure and talent pool to support AI initiatives?",
      imgSrc: icon3,
    },
    {
      text: "Does your company foster a culture of innovation and experimentation?",
      imgSrc: icon4,
    },
    {
      text: "How digitally mature are your various departments?",
      imgSrc: icon5,
    },
  ];

  const rectangles2 = [
    {
      icon: icon6,
      text: "Identifies gaps between your AI aspirations and your current digital capabilities.",
    },
    {
      icon: icon7,
      text: "Provides actionable insights on how to bridge those gaps with a prioritized roadmap.",
    },
    {
      icon: icon8,
      text: "Empowers leaders to make data-driven decisions about AI adoption and digital transformation initiatives.",
    },
  ];

  let startXRef = useRef(0); // Reference to store initial X position on mouse down
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startScrollLeft, setStartScrollLeft] = useState(0);

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const container = scrollContainerRef.current;
    const distance = e.pageX - startXRef.current;
    const maxScrollDistance = container.scrollWidth - container.clientWidth;

    let newScrollLeft = startScrollLeft - distance;

    // Implement circular scrolling behavior
    if (newScrollLeft < 0) {
      newScrollLeft = maxScrollDistance + newScrollLeft; // Wrap around to the end
    } else if (newScrollLeft > maxScrollDistance) {
      newScrollLeft = newScrollLeft - maxScrollDistance; // Wrap around to the beginning
    }

    container.scrollLeft = newScrollLeft;
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    startXRef.current = e.pageX;
    setStartScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const scrollToNext = () => {
    const container = scrollContainerRef.current;
    const cardWidth = container.firstElementChild.clientWidth; // Get width of the first card
    const scrollDistance = cardWidth + 20; // Adjust as needed, considering margin/padding

    container.scrollLeft += scrollDistance;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmailId] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmId, setUtmId] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmContent, setUtmContent] = useState("");
  const [errors, setErrors] = useState({});
  const [agreement, setAgreement] = useState(true); // Set default to true for pre-selected checkbox

  const [countryCode, setCountryCode] = useState("");
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

  const databases = new Databases(client);

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate Name
    if (!name) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      newErrors.name =
        "Name can only contain alphabetic characters and spaces.";
      isValid = false;
    }

    // Validate Company Name
    if (!companyName) {
      newErrors.companyName = "Company Name is required.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(companyName)) {
      newErrors.companyName =
        "Company Name can only contain alphabetic characters and spaces.";
      isValid = false;
    }

    // Validate Phone Number
    if (!phoneNumber) {
      newErrors.phoneNumber = "Mobile Number is required.";
      isValid = false;
    } else {
      if (countryCode === "91") {
        const validStartDigits = /^[6789]/;
        if (phoneNumber.length !== 12) {
          newErrors.phoneNumber =
            "Contact Number for India must be exactly 10 digits.";
          isValid = false;
        } else if (!validStartDigits.test(phoneNumber.slice(-10))) {
          newErrors.phoneNumber =
            "Contact Number for India must start with 6, 7, 8, or 9.";
          isValid = false;
        } else if (!isValidPhone) {
          newErrors.phoneNumber = "Contact Number is invalid.";
          isValid = false;
        }
      } else if (!isValidPhone) {
        newErrors.phoneNumber = "Contact Number is invalid.";
        isValid = false;
      }
    }

    const ignoreMail = ["gmail", "yahoo", "hotmail", "outlook", "rediff"];

    // Validate Email
    if (!email) {
      newErrors.email = "Email Id is required.";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      newErrors.email = "Email Id is invalid.";
      isValid = false;
    } else {
      // Extract the domain from the email
      const emailDomain = email.split("@")[1]?.split(".")[0];

      // Check if the domain is in the ignoreMail list
      if (ignoreMail.includes(emailDomain)) {
        newErrors.email = "Email Id with this domain is not allowed.";
        isValid = false;
      }
    }

    // Set errors and return validation status
    setErrors(newErrors);
    return isValid;
  };

  const formatPhoneNumber = (phone, dialCode) => {
    return `+${dialCode}-${phone.split(dialCode).join("")}`;
  };

  const handlePhoneNumberChange = (phone, country) => {
    // Remove the country dial code and any non-numeric characters from the phone number
    const cleanPhone = phone
      .replace(new RegExp(`^\\+${country.dialCode}`), "")
      .replace(/\D/g, "");

    // Update the phone number and country code states
    setPhoneNumber(cleanPhone);
    setCountryCode(country.dialCode);
  };

  const isValidPhone = useMemo(() => {
    return isValidPhoneNumber("+" + phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get("utm_source") || "");
    setUtmMedium(searchParams.get("utm_medium") || "");
    setUtmCampaign(searchParams.get("utm_campaign") || "");
    setUtmId(searchParams.get("utm_id") || "");
    setUtmTerm(searchParams.get("utm_term") || "");
    setUtmContent(searchParams.get("utm_content") || "");
  }, []);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_COLLECTION_ID
      );

      console.log("Response:", response);

      const emailExists = response.documents.some((doc) => doc.email === email);

      const formattedPhoneNumber = formatPhoneNumber(phoneNumber, countryCode);
      const phoneExists = response.documents.some(
        (doc) => doc.phoneNumber.slice(-10) === formattedPhoneNumber.slice(-10)
      );

      if (emailExists || phoneExists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: emailExists ? "Email already exists." : "",
          phoneNumber: phoneExists ? "Phone number already exists." : "",
        }));
        return;
      }

      await databases.createDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_COLLECTION_ID,
        ID.unique(),
        {
          name,
          companyName,
          phoneNumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      setIsModalOpen(false);
      // setIsSuccessOpen(true);
      navigate("/thankyou");
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const handleScrollLeft = () => {
    if (scrollContainerrRef.current) {
      scrollContainerrRef.current.scrollBy({
        left: -500, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerrRef.current) {
      scrollContainerrRef.current.scrollBy({
        left: 500, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  const scrollContainerRef2 = useRef(null);

  useEffect(() => {
    const container = scrollContainerRef2.current;
    if (!container) return;

    const scrollAmount = 2; // Amount to scroll per interval
    const interval = 35; // Time in milliseconds between scrolls

    const autoScroll = setInterval(() => {
      container.scrollLeft += scrollAmount;

      // Loop back to start if we reach the end
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        container.scrollLeft = 0;
      }
    }, interval);

    return () => clearInterval(autoScroll); // Cleanup on component unmount
  }, []);

  const images = [
    MobileStage11,
    MobileStage22,
    MobileStage33,
    MobileStage44,
    MobileStage55,
    MobileStage66,
    MobileStage77,
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  function useIntersectionObserver(ref, options = {}) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, options);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        observer.disconnect();
      };
    }, [ref, options]);

    return isIntersecting;
  }

  const sectionRef = useRef(null);
  // const isVisible = useIntersectionObserver(sectionRef, { threshold: 0.1 });

  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const currentElement = observerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        // settrigger everytime
        // setIsVisible(entry.isIntersecting);

        // set trigger once
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
          observer.unobserve(currentElement); // Stop observing after it has triggered
        }
      },
      {
        root: null, // Observe relative to the viewport
        rootMargin: "0px",
        threshold: 0.3, // Trigger when 10% of the target is visible
      }
    );

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleLinkClick = (e) => {
    e.preventDefault();
    setIsPopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const [isPopup2Open, setIsPopup2Open] = useState(false);
  const handleLinkClick2 = (e) => {
    e.preventDefault();
    setIsPopup2Open(true);
  };
  const handleClosePopup2 = () => {
    setIsPopup2Open(false);
  };

  return (
    <div className="Main">
      <div className="bg-gradient-custom flex justify-center items-center ">
     

      <div className="relative w-full h-screen overflow-hidden z-10 -lg:top-10 md:top-0 -top-5">
          <img
            src={whitee}
            alt="logo"
            className="absolute 2xl:h-24 2xl:w-auto 2xl:top-20 2xl:left-40 top-10 left-1/2 transform -translate-x-1/2 lg:left-20 lg:transform-none lg:translate-x-0 lg:top-10 lg:w-auto lg:h-12 justify-center md:left-20 md:transform-none md:translate-x-0 md:top-10 md:w-auto md:h-16 h-16 w-72 "
          />

          <div className="absolute  top-36 lg:top-[120px] items-center 2xl:left-44 2xl:top-52 md:top-32 md:left-20 lg:left-20 text-container text-white -space-y-0 z-30">
            <p className="flex 2xl:text-5xl justify-center text-center md:text-bold lg:justify-start text-thin  font-display lg:text-4xl  font-bold text-white text-xl mt-[-40px] mb-[20px] rounded-lg px-2 lg:mb-8 gradient-text2 top-10 2xl:mb-10">
              AI Readiness & Digital Maturity Assessment Tool
            </p>
            
            <p className="flex 2xl:text-5xl justify-center text-center md:text-bold  md:justify-start text-thin font-display lg:text-5xl font-bold text-yellow-200 text-2xl mt-[-40px] mb-[40px] rounded-lg px-0 lg:mb-8 top-10 2xl:mb-20 ">
              Generate 5x ROI
            </p>

            <br />
            <div className="flex lg:flex-row md:flex-row flex-col space-y-6 lg:space-y-0 md:space-y-0 ">
              <p className="text-white 2xl:text-6xl lg:text-5xl top-2 font-bold font-display lg:text-left md:text-2xl text-center md:text-left text-2xl   ">
                Don't just dream of <br />
                <span className="gradient-text">AI Transformation,</span>
                <br /> make it a reality.
              </p>

              <div className="flex max-sm:hidden   flex-col 2xl:p-4 bg-white p-2 lg:p-4 rounded-xl shadow-md lg:max-w-2xl 2xl:-mr-60 lg:ml-auto md:ml-auto lg:-mr-40 md:-mr-20 mr-auto ml-auto items-center justify-center ">
                <p className="top-paragraph text-center text-custom-text text-xl   lg:text-2xl md:text-xl 2xl:text-4xl  z-10 font-display  mb-4 ">
                  Discover Your AI Readiness <br />
                  <p className="text-custom-text flex font-display justify-center ">
                    in Just{" "}
                    <span className="font-bold font-display   justify-center">
                      &nbsp; 15 Minutes
                    </span>
                  </p>
                </p>

                <button
                  className=" group bg-[#C62B6D] 2xl:text-4xl px-4 py-2 lg:px-10 lg:py-3 rounded-lg text-white font-display border lg:font-semibold md:font-semibold font-medium text-sm  lg:text-lg  flex items-center justify-center  lg:mx-0 hover:bg-[#FFFFFF] hover:text-[#666666] hover:border-[#666666]"
                  onClick={handleButtonClick}
                >
                  AI Readiness Assessment
                  <span className="ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-4 lg:w-6 lg:h-5 2xl:h-8 2xl:w-10 transition-transform duration-300 group-hover:-rotate-45"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M2 12h20m-7-7 7 7-7 7"
                      />
                    </svg>
                  </span>
                </button>
              </div>

              {/* Mobile Version */}
              <div className="flex flex-col justify-center items-center top-0 ">
                <iframe

                  src="https://www.youtube.com/embed/g2bGWGtnZuw?autoplay=1&mute=1&loop=1&playlist=g2bGWGtnZuw&rel=0"
                  frameBorder="0"
                  allowFullScreen
                  className="md:hidden z-10 w-3/4 h-1/2 aspect-video flex flex-row rounded-2xl object-contain mb-4 "
                  title="AI Readiness Video"

                ></iframe>
                <div className="relative sm:hidden flex flex-col bg-white p-3 rounded-md shadow-md max-w-md mx-auto items-center justify-center">
                  <p className="top-paragraph text-center text-custom-text text-2xl font-display mb-4">
                    Discover Your AI Readiness <br />
                    <span className="text-custom-text flex font-display justify-center text-2xl">
                      in Just{" "}
                      <span className="font-bold font-display justify-center">
                        &nbsp; 15 Minutes
                      </span>
                    </span>
                  </p>

                  <button
                    className="group bg-[#C62B6D] w-64 h-[45px] px-2 mt-2 py-2 rounded-md text-white font-display border border-none font-medium text-md flex items-center justify-center hover:bg-[#FFFFFF] hover:text-[#666666] hover:border-[#666666] absolute bottom-[-20%] left-1/2 transform -translate-x-1/2 "
                    onClick={handleButtonClick}
                  >
                    AI Readiness Assessment
                    <span className="ml-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-4 h-4 transition-transform duration-300 group-hover:-rotate-45"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M2 12h20m-7-7 7 7-7 7"
                        />
                      </svg>
                    </span>
                  </button>
                </div>

                <button
                  id="form-open-button"
                  onClick={handleButtonClick}
                  className="fixed bottom-4 right-2 m-4 p-0 bg-transparent border-none" 
                >
                  <img
                    src={floating_iconn}
                    alt="floating"
                    className="w-16 h-16 md:w-20 md:h-20 lg:w-22 lg:h-22"
                  />
                </button>
              </div>
            </div>
            <div className="xl:mt-4">
              <iframe

                src="https://www.youtube.com/embed/g2bGWGtnZuw?autoplay=1&mute=1&loop=1&playlist=g2bGWGtnZuw&rel=0"

                frameBorder="0"
                allowFullScreen
                className="max-md:hidden z-10 md:w-2/5 md:h-1/4 w-1/2 h-1/2 aspect-video flex flex-row rounded-2xl object-contain md:m-4 mb-6 2xl:w-2/4 2xl:-ml-6  "
                title="Fortune Cookies Video"
              ></iframe>
            </div>
          </div>
          <div
            className="image-container 
          max-md:hidden 
                w-full 
                h-auto 
                lg:-top-7  
                lg:w-auto 
                xl:w-2/5 
                2xl:-top-60 
                flex 
                flex-wrap 
                justify-center 
                gap-4"
          >
            <img src={home4} className="w-full sm:w-1/2 md:w-1/3 lg:w-auto" />
            <img src={home1} className="w-full sm:w-1/2 md:w-1/3 lg:w-auto" />
            <img src={home2} className="w-full sm:w-1/2 md:w-1/3 lg:w-auto" />
            <img src={home3} className="w-full sm:w-1/2 md:w-1/3 lg:w-auto" />
            <img src={home4} className="w-full sm:w-1/2 md:w-1/3 lg:w-auto" />
            <img src={home1} className="w-full sm:w-1/2 md:w-1/3 lg:w-auto" />
          </div>

          {/* Mobile Version */}

          <div
            className="image-container 
    flex 
    justify-center 
    gap-0
    h-screen
    w-screen
    -translate-y-1/5
    overflow-hidden"
          >
            <img src={Mobile44} className="w-full h-full object-cover" />
            <img src={Mobile11} className="w-full h-full object-cover" />
            <img src={Mobile22} className="w-full h-full object-cover" />
            <img src={Mobile33} className="w-full h-full object-cover" />
            <img src={Mobile44} className="w-full h-full object-cover" />
            <img src={Mobile11} className="w-full h-full object-cover" />
          </div>
        </div>
       

      </div>

      <div className="flex flex-col -ml-4 md:flex-row md:items-center lg:mb-[80px] mb-[140px] 2xl:mb-[20px]">
        <div className="mb-5 md:mb-0 max-lg:hidden">
          <img
            src={img1}
            alt="Description of the image"
            className="w-[calc(100%-3.5rem)] ml-12 h-auto"
          />
        </div>
        <div className="relative flex-1 mb-5 md:mb-0 lg:hidden overflow-hidden pl-12 max-sm:pl-8">
          <img
            src={img1}
            alt="Description of the image"
            className="w-full h-full ml-1.5 object-cover max-sm:scale-110 max-sm:pt-3"
          />

          {/* <div className='absolute inset-0 flex items-center justify-center'>
            <p className='text-white text-sm md:text-xl sm:text-md mb-16 font-medium font-display'>
              Is your organisation prepared for
            </p>
          </div> */}
        </div>
      </div>
      <div className="flex  justify-center -rotate-90 -translate-y-72">
        <GSAPAnimation />
      </div>

      <div className="lg:-mt-[342px] -mt-[340px] py-10 flex justify-center px-8  ">
        <div className="max-w-4xl w-full text-center bg-[#ECEEF8] p-6 rounded-lg 2xl:max-w-[90rem] ">
          <div className="text-3xl md:text-5xl lg:text-5xl lg:mb-3 lg:mt-2 md:mb-3 2xl:text-8xl text-[#575757] font-display font-bold">
            Don't take a blind leap
          </div>
          <div className="text-base md:text-lg lg:text-lg 2xl:text-4xl font-display text-[#3E59B7] font-medium mt-4">
            The Bridge You Need to Turn AI
            <br />
            Ambition into Strategy.
          </div>

          <br />
          <div className="flex justify-center">
            <button
              className="group bg-[#C62B6D] text-button p-4 md:px-10 lg:px-12 py-4 rounded-xl text-white font-display border font-semibold text-sm lg:text-lg flex items-center justify-center hover:bg-[#FFFFFF] hover:text-[#666666]  hover:border-[#666666] z-10"
              onClick={handleButtonClick}
            >
              AI Readiness Assessment
              <span className="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-4 h-4 transition-transform duration-300 group-hover:-rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M2 12h20m-7-7 7 7-7 7"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="bg-[#FFEEF5]">
        <div className="bg-[#FFEEF5] py-4 px-4 lg:py-12 flex font-display justify-center">
          <div className="max-w-6xl w-full bg-[#FFF8FB] p-10 rounded-lg text-center">
            <div className="  text-lg md:p-0 md:text-5xl font-bold font-display text-[#3E59B7] mb-10">
              Why is understanding your current
              <br /> AI readiness crucial?
            </div>
            {/* Mobile Version */}
            <div className="lg:hidden text-[#293B79] lg:text-center text-center lg:text-2xl md:pb-2 font-display max-w-md md:text-lg md:max-w-3xl lg:max-w-5xl mt-18 md:mt-2 2xl:max-w-9xl 2xl:text-2xl font-normal mx-auto">
              Without a clear{" "}
              <span className=" font-bold font-display text-black lg:mx-1">
                understanding of your current state,{" "}
              </span>
              setting realistic goals for digital transformation and{" "}
              <span className="font-bold font-display text-black lg:mx-1">
                AI adoption becomes a guessing game.
              </span>
              <br />
              <br />
              <div className="lg:text-justify text-center">
                This can lead to wasted resources, failed implementations, and
                missed opportunities.
              </div>
            </div>
            {/* laptop Version */}
            <div className="max-lg:hidden text-[#293B79] lg:text-center text-center lg:text-2xl md:pb-2 font-display max-w-md md:text-lg md:max-w-3xl lg:max-w-5xl mt-18 md:mt-2 2xl:max-w-9xl 2xl:text-2xl font-normal mx-auto">
              Without a clear{" "}
              <span className=" font-bold font-display text-white bg-[#C62B6D] lg:mx-1">
                understanding of your current state,{" "}
              </span>
              setting realistic goals for digital transformation and{" "}
              <span className="font-bold font-display text-white bg-[#C62B6D] lg:mx-1">
                AI adoption becomes a guessing game.
              </span>
              <div className="lg:text-justify text-center">
                This can lead to wasted resources, failed implementations, and
                missed opportunities.
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#FFEEF5] flex justify-center">
          <div className="max-w-6xl w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 p-4 md:p-0">
            <div className="flex rounded-lg flex-row md:flex-col  items-start bg-[#FFF8FB] p-6 md:p-8 lg:p-10 2xl:p-12 flex-1">
              <img
                src={icon6}
                alt="Image 1"
                className="w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 2xl:w-16 2xl:h-16"
              />
              <div className="mt-0 md:mt-3 ml-2 text-left">
                <div className="text-black text-md lg:text-base 2xl:text-xl font-display">
                  Identifies gaps between your AI aspirations and your current
                  digital capabilities.
                </div>
              </div>
            </div>

            <div className="flex flex-row md:flex-col rounded-lg items-start bg-[#FFF8FB] p-6 md:p-8 lg:p-10 2xl:p-12 flex-1">
              <img
                src={icon7}
                alt="Image 2"
                className="w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 2xl:w-16 2xl:h-16"
              />
              <div className="mt-0 md:mt-3 ml-2 text-left">
                <div className="text-black text-md lg:text-base 2xl:text-xl font-display">
                  Provides actionable insights on how to bridge those gaps with
                  a prioritized roadmap.
                </div>
              </div>
            </div>

            <div className="flex flex-row md:flex-col rounded-lg items-start bg-[#FFF8FB] p-6 md:p-8 lg:p-10 2xl:p-12 flex-1">
              <img
                src={icon8}
                alt="Image 3"
                className="w-10 h-10 md:w-12 md:h-12 lg:w-14 lg:h-14 2xl:w-16 2xl:h-16"
              />
              <div className="mt-0 md:mt-3 ml-2 text-left">
                <div className="text-black text-md lg:text-base 2xl:text-xl font-display">
                  Empowers leaders to make data-driven decisions about AI
                  adoption and digital transformation initiatives.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-[#FFEEF5] pb-10 overflow-hidden">
        <div className="flex flex-col items-center justify-center text-center relative">
          <div className="mt-10">
            <button
              className="group bg-[#C62B6D] text-button px-10 lg:px-12 py-4 rounded-xl text-white font-display border font-semibold text-sm lg:text-lg flex items-center justify-center hover:bg-[#FFFFFF] hover:text-[#666666]  hover:border-[#666666]"
              onClick={handleButtonClick}
            >
              Take the Assessment Now
              <span className="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-5 transition-transform duration-300 group-hover:-rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M2 12h20m-7-7 7 7-7 7"
                  />
                </svg>
              </span>
            </button>
            <div className="w-44 mr-8 mt-5 lg:pr-28 h-auto md:w-auto md:h-auto md:mt-10"></div>

            {isModalOpen && (
              <div className="fixed z-50 inset-0 md:p-0 p-10 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="relative bg-[#F1F6FF] p-8 rounded-lg max-w-md w-full">
                  <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={closeModal}
                    id="form-close-button"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                  <div className="text-lg text-center mb-4">
                    <div className="text-2xl font-bold mb-4 text-[#444444] text-center font-display">
                      <p>
                        Access Your{" "}
                        <span className="text-[#C62B6D]">AI Readiness</span>
                        <br />
                        In Just 15 Mins!
                      </p>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-[#5F6377] mb-2 text-left">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-3 py-1 border rounded text-black border-[#CACACA] "
                        required
                      />
                      {errors.name && (
                        <p className="text-red-500 text-sm">{errors.name}</p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-[#5F6377] mb-2 text-left">
                        Business/Company Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmailId(e.target.value)}
                        className="w-full px-3 py-1 border rounded text-black border-[#CACACA]"
                        required
                      />
                      {errors.email && (
                        <p className="text-red-500 text-sm">{errors.email}</p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-[#5F6377] mb-2 text-left">
                        Contact Number
                      </label>
                      <PhoneInput
                        country={"in"}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        inputClass="custom-phone-input2"
                        buttonClass=" rounded"
                        dropdownClass=" rounded"
                        className="text-left"
                      />
                      {errors.phoneNumber && (
                        <p className="text-red-500 text-sm">
                          {errors.phoneNumber}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-[#5F6377] mb-2 text-left">
                        Company Name
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="w-full px-3 py-1 border rounded border-[#CACACA] text-black"
                        required
                      />
                      {errors.companyName && (
                        <p className="text-red-500 text-sm">
                          {errors.companyName}
                        </p>
                      )}
                    </div>

                    <div className="mb-4 mt-4">
                      <button
                        type="submit"
                        className="w-full px-4 py-2 mt-4 rounded font-display bg-[#C62B6D] text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {isSuccessOpen && (
              <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
                  <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={closeSuccessModal}
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                  <h2 className="text-2xl font-bold mb-4">Success!</h2>
                  <p className="mb-4">
                    Your request has been sent successfully.
                  </p>
                  <button
                    className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                    onClick={closeSuccessModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div
        ref={observerRef}
        className="relative max-md:hidden"
        id="scroll-view"
      >
        <img src={Finall} alt="Final" className="lg:w-full lg:h-full" />
        <img
          src={stage11}
          alt="stages"
          className={`absolute 2xl:top-[630px] md:top-[230px] top-[70px] w-32 h-auto translate-y-32 lg:w-auto lg:top-[316px] lg:translate-y-9 ${
            isVisible ? "fadeInDelay1 fadeInFromBottom" : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
        <img
          src={stage22}
          alt="stages"
          className={`absolute lg:-bottom-[15px] md:bottom-[17px] md:left-[90px] 2xl:bottom-[60px] lg:translate-x-80 lg:left-36 -bottom-[15px] left-12 lg:w-auto translate-x-80 w-32 h-auto ${
            isVisible ? " fadeInDelay2 fadeInFromTop " : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
        <img
          src={stage33}
          alt="stages"
          className={`absolute md:top-[185px] md:left-[180px] lg:top-[208px] 2xl:top-[500px] 2xl:left-[400px] lg:left-64 lg:w-auto w-32 h-auto top-[49px] left-24 ${
            isVisible ? " fadeInDelay3 fadeInFromBottom " : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
        <img
          src={stage44}
          alt="stages"
          className={`absolute md:bottom-[84px] md:left-[300px] lg:bottom-9 lg:left-96 2xl:bottom-[165px] 2xl:left-[650px] lg:w-auto lg:translate-x-40 w-32 h-auto  bottom-[12px] left-36 ${
            isVisible ? " fadeInDelay4 fadeInFromTop " : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
        <img
          src={stage55}
          alt="stages"
          className={`absolute md:top-[98px] md:right-[250px] 2xl:top-[190px] 2xl:right-[550px] lg:top-[90px] lg:right-80 lg:w-auto lg:translate-x-60 top-[36px] right-28 w-32  h-auto ${
            isVisible ? "fadeInDelay5 fadeInFromBottom " : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
        <img
          src={stage66}
          alt="stages"
          className={`absolute md:bottom-[152px] md:right-[100px]  2xl:bottom-[400px] 2xl:right-[300px] lg:bottom-[177px] lg:right-36 lg:w-auto w-32 h-auto bottom-8 right-8 ${
            isVisible ? " fadeInDelay6 fadeInFromTop " : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
        <img
          src={stage77}
          alt="stages"
          className={`absolute md:-top-[6px] 2xl:-top-[30px] 2xl:-right-[16px] lg:-top-[68px] lg:-right-8 w-32 lg:w-auto h-auto -top-[18px] -right-2 ${
            isVisible ? " fadeInDelay7 fadeInFromBottom " : ""
          }`}
          style={{ transform: "scale(0.65)", visibility: "hidden" }}
        />
      </div> */}

      <div className=" max-md:hidden w-full h-1/2">
        <video
          className="w-full h-auto object-cover"
          autoPlay
          loop
          muted

          loading="lazy"

          playsInline
        >
          <source src={journey} type="video/mp4" />
          Your browser does not support the video.
        </video>
      </div>

      {/* animation part */}

      <div className="relative md:hidden">
        <img
          src={images[currentIndex]}
          alt={`carousel ${currentIndex}`}
          className="carousel-image"
        />
      </div>

      <div className="flex justify-center items-center  mt-5 mb-5">
        <button
          className="max-sm:hidden group bg-[#C62B6D] text-button px-10 lg:px-12 py-4 rounded-xl text-white font-display border font-semibold text-sm lg:text-lg flex items-center justify-center hover:bg-[#FFFFFF] hover:text-[#666666]  hover:border-[#666666]"
          onClick={handleButtonClick}
        >
          Access your Organisation's AI Readiness
          <span className="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-5 transition-transform duration-300 group-hover:-rotate-45"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M2 12h20m-7-7 7 7-7 7"
              />
            </svg>
          </span>
        </button>
        <button
          className="group bg-[#C62B6D] lg:hidden md:hidden sm:hidden text-button px-10 lg:px-12 py-4 rounded-xl text-white font-display border font-semibold text-sm lg:text-lg flex items-center justify-center hover:bg-[#FFFFFF] hover:text-[#666666]  hover:border-[#666666]"
          onClick={handleButtonClick}
        >
          AI Readiness Assessment
          <span className="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-5 transition-transform duration-300 group-hover:-rotate-45"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M2 12h20m-7-7 7 7-7 7"
              />
            </svg>
          </span>
        </button>
      </div>

      <div className="relative bg-[#FFEFF6]  bg-none py-10">
        <img
          src={pink_purple_topleft}
          alt="Bubble"
          className="absolute top-0 left-0 transform -translate-x-64 h-64 w-38"
        />
        <div className="flex flex-col items-center justify-center text-center">
          <div className="text-custom-text max-md:-mt-8 mt-10">
            <p className="relative font-display max-md:text-2xl text-5xl max-md:font-bold font-semibold mb-2 lg:mb-4">
              AI Readiness & Digital Maturity
            </p>

            <p className="font-display max-md:font-bold font-semibold max-md:text-2xl text-5xl">
              Assessment Tool
            </p>
            <p className="lg:text-2xl text-xl max-md:font-semibold mt-6 max-md:mt-2  max-md:-mb-4 text-[#666666] mb-4 font-display">
              Get Your Report in{" "}
              <span className="bg-[#3e59b7] font-semibold px-1 text-white font-display">
                15 Minutes
              </span>
            </p>
          </div>
        </div>
        <div className="text-[#565656] mt-6 text-center"></div>

        <div className="flex flex-col md:flex-row items-center justify-center space-y-10 md:space-y-0 md:space-x-8 lg:space-x-10 xl:space-x-12 mt-10 px-4 lg:px-0">
          <div className="bg-white max-md:rounded-2xl rounded-lg text-left max-md:-m-2 md:text-left mb-10 md:mb-0 py-4 px-6">
            <p className="font-semibold px-1  text-sm text-white font-display lg:p-1 lg:text-sm mb-3 w-16 text-center border  rounded-full bg-custom-text">
              Part 1
            </p>

            <p className="text-lg text-custom-text font-display font-bold lg:text-3xl 2xl:text-4xl mb-2">
              AI Readiness Assessment
            </p>
            <p className="text-lg text-custom-text font-display font-bold lg:text-xl 2xl:text-2xl mb-4">
              For Business Leaders
            </p>
            <p className="text-md max-md:text-sm max-md:text-custom-text text-[#666666] font-display max-w-sm md:max-w-xs lg:max-w-md 2xl:text-xl font-medium lg:text-lg mb-4">
              Complete a self-assessment designed to analyze your organization’s
              overall readiness for adopting AI technologies across key
              dimensions.
            </p>

            <div className="flex item-center justify-center gradient-text3">
              <button
                className="group px-10 lg:px-4 py-2 rounded-xl text-custom-text font-display border border-custom-text font-semibold text-sm lg:text-lg flex items-center justify-center hover:border-[#666666]"
                onClick={handleButtonClick}
              >
                Get Your Report Now
                <span className="ml-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#C62B6D"
                    className="w-6 h-5 group-hover:rotate-45 transition-transform duration-300"
                    style={{ transform: "rotate(-30deg)" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="4"
                      d="M2 12h20m-7-7 7 7-7 7"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="bg-white max-md:rounded-2xl rounded-lg text-left md:text-left  max-md:-m-2 py-4 px-6">
            <p className="text-sm font-semibold px-1 text-white font-display lg:p-1 lg:text-sm mb-3 w-16 text-center border  rounded-full bg-custom-text">
              Part 2
            </p>

            <p className="text-lg ma text-custom-text font-display font-bold lg:text-3xl 2xl:text-4xl mb-2">
              Digital Maturity Assessment
            </p>
            <p className="text-lg text-custom-text font-display font-bold lg:text-xl 2xl:text-2xl mb-4">
              For Department Heads
            </p>
            <p className="text-md max-md:text-sm max-md:text-custom-text text-[#666666] font-display max-w-sm md:max-w-xs lg:max-w-md 2xl:text-xl font-medium lg:text-lg mb-4">
              Department heads complete assessment for their department.
              Tailored reports for each department, detailing digital maturity.
            </p>
            <div className="flex justify-center gradient-text3">
              <button
                className="group px-10 lg:px-4 py-2 rounded-xl text-custom-text font-display border border-custom-text font-semibold text-sm lg:text-lg flex items-center justify-center hover:border-[#666666]"
                onClick={handleButtonClick}
              >
                Get Your Report Now
                <span className="ml-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#C62B6D"
                    className="w-6 h-5 group-hover:rotate-45 transition-transform duration-300"
                    style={{ transform: "rotate(-30deg)" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="4"
                      d="M2 12h20m-7-7 7 7-7 7"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className=" relative flex flex-col  bg-custom-gradient2 w-full text-center overflow-hidden ">
        <img
          src={diamonds}
          alt="Top Left Image"
          className="max-md:hidden md:block absolute top-0 md:w-36 md:h-auto md:-left-10 lg:w-44 lg:h-auto lg:-left-12 lg:top-4 2xl:w-48 2xl:h-auto 2xl:-left-12"
        />
        <div className="relative w-full">
          <div className="flex flex-col md:items-center lg:justify-end 2xl:justify-center lg:mt-10">
            <div className="flex flex-col md:pl-10">
              <div className="flex max-md:flex-col text-left">
                <div className="flex flex-row md:flex-row text-white text-xl max-w-80 ml-8 mt-8 text-pretty md:text-left md:text-xl lg:text-3xl 2xl:text-4xl md:mt-28 lg:mt-24 2xl:mt-40 lg:px-10 lg:mr-10 md:max-w-lg lg:max-w-lg 2xl:max-w-xl font-semibold font-display ">
                  Our assessment tool addresses this critical gap by helping you
                  answer essential questions
                </div>
                <div
                  className="scroll-container overflow-x-auto whitespace-nowrap flex w-full p-5 md:p-0"
                  ref={scrollContainerrRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  {rectangles.map((rectangle, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:mt-16 lg:mt-12 2xl:mt-24 bg-white p-4 pr-7 pl-5 rounded-2xl m-2 h-44 md:h-[200px] lg:h-[250px] 2xl:h-[300px] 2xl:scale-115 mb-2 md:mb-24"
                    >
                      <img
                        src={rectangle.imgSrc}
                        alt={`Image ${index + 1}`}
                        className="ml-2 mt-4 w-7 h-7 md:w-12 md:h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20 md:ml-6 md:mt-7"
                      />
                      <div className="text-sm ml-2 mt-3 lg:text-lg 2xl:text-2xl 2xl:max-w-32 md:ml-6 md:mt-5 text-left font-display text-wrap min-w-44 md:min-w-[350px] lg:min-w-[430px] 2xl:min-w-[500px]">
                        {rectangle.text}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="max-md:hidden relative items-center justify-center bottom-20 -right-[105px] transform -translate-x-1/2 flex space-x-2 mt-4">
              <button
                className="flex items-center justify-center w-10 h-10 bg-[#C62B6D] text-[#3E1D9E] font-bold rounded-full border border-none cursor-pointer"
                onClick={handleScrollLeft}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#FFFFFF"
                  className="w-7 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M22 12H2m7-7-7 7 7 7"
                  />
                </svg>
              </button>
              <button
                className="flex items-center justify-center w-10 h-10 bg-[#C62B6D] text-[#3E1D9E] font-bold rounded-full border border-none cursor-pointer"
                onClick={handleScrollRight}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#FFFFFF"
                  className="w-7 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M2 12h20m-7-7 7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <img
          src={cubebottom}
          alt="Decorative Image"
          className=" hidden block w-20 -mt-16 h-auto "
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-y-4 gap-x-5 md:p-10 lg:p-24 2xl:p-32 mx-auto w-[90%]">
        <div className="hidden text-[#666666] md:text-left text-center rounded-xl text-2xl mt-2 md:text-4xl lg:text-5xl 2xl:text-6xl font-display font-bold 2xl:w-full md:mx-0">
          <p>Benefits of</p>
          <p>the tool</p>

          <div className="mt-2 gradient-text3">
            <p className="group py-1 rounded-xl text-custom-text font-display font-bold gap-x-2 text-lg flex flex-col md:flex-row items-center justify-center">
              What You Gain
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="text-custom-text mt-1 md:mt-0 transform md:rotate-0 rotate-90"
              >
                <rect x="-4" y="9" width="18" height="6" />
                <polygon points="14,4 24,12 14,20" />
              </svg>
            </p>
          </div>
        </div>

        <div className="text-[#666666] md:text-left text-center rounded-xl text-4xl mt-6 md:text-4xl lg:text-5xl 2xl:text-6xl font-display font-bold 2xl:w-full md:mx-0">
          <p>Benefits of the tool</p>
          <div className="-mt-1 gradient-text3">
            <p className="group -mt-2 py-1 rounded-xl text-custom-text font-display font-bold gap-x-2 text-2xl flex flex-col md:flex-row items-center justify-center">
              What You Gain
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="text-custom-text mt-3 md:mt-0 transform md:rotate-0 rotate-90"
              >
                <rect x="-4" y="9" width="18" height="6" />
                <polygon points="14,4 24,12 14,20" />
              </svg>
            </p>
          </div>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display  ">
            Develop a Strong AI Vision & Strategy
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Align AI initiatives with our long-term business goals.
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Identify Strengths & Weaknesses
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Uncover areas where you excel and areas needing improvement.
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Bridge Skills and Gaps
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Develop or acquire the necessary talent to effectively utilize AI.
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Make Data-Driven Decisions
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Invest in AI solutions that deliver a strong return on investment
            (ROI).
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Optimize Resource Allocation
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Allocate resources effectively to bridge digital maturity gaps and
            support AI initiatives.
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Uncover Departmental Digital Maturity
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Identify areas within their departments that are digitally advanced
            or lagging.
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Craft a Transformation Roadmap
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Develop a clear plan for each department to achieve higher digital
            maturity levels.
          </p>
        </div>

        <div className="border border-custom1 rounded-3xl p-4 h-40 flex flex-col items-start hover:bg-[#C62B6D] text-[#666666] hover:text-white justify-left">
          <p className="mb-2 text-lg md:text-sm lg:text-lg 2xl:text-xl font-semibold font-display">
            Prepare for AI Integration
          </p>
          <p className="text-md md:text-sm lg:text-md 2xl:text-lg font-display">
            Ensure their departments have the digital foundation necessary to
            leverage AI successfully.
          </p>
        </div>
      </div>
      <div>
        <ContactSection1 />
      </div>
    </div>
  );
};
export default HomepageBE;
