import React from 'react';

const Popup = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-8"
        >


            <div className=" bg-white p-8 md:px-10 md:py-2 rounded-lg text-black shadow-lg max-w-md w-full relative overflow-auto max-h-[94vh] custom-scrollbar"
            >
                <div className='items-end justify-end min-w-full flex sticky top-0 -mb-4'>

                    <button
                        onClick={onClose}
                        className=" text-gray-500 hover:text-gray-800 text-4xl -mr-8"
                    >
                        &times;
                    </button>
                </div>
                <div className='font-display'>
                    <h1 className="  text-3xl font-bold mb-4">Terms of Service</h1>

                    <h2 className="text-2xl font-semibold mb-2">AGREEMENT TO OUR LEGAL TERMS</h2>
                    <p className="mb-4">
                        We are Random Walk (‘Company’, ‘we’, ‘us’, or ‘our’), a company registered in India at LBR Towers, Anna Salai, Chennai, India, Chennai, Tamilnadu 600021.
                    </p>
                    <p className="mb-4">
                        We operate the website <a href="http://www.randomwalk.ai" className="text-blue-500 underline">http://www.randomwalk.ai</a> (the ‘Site’), as well as any other related products and services that refer or link to these legal terms (the ‘Legal Terms’) (collectively, the ‘Services’).
                    </p>
                    <p className="mb-4">
                        We provide AI integration and managed services.
                    </p>
                    <p className="mb-4">
                        You can contact us by phone at <a href="tel:+917845757355" className="text-blue-500 underline">+91 784 575 7355</a>, email at <a href="mailto:enquiry@randomwalk.ai" className="text-blue-500 underline">enquiry@randomwalk.ai</a>, or by mail to LBR Towers, Anna Salai, Chennai, India, Chennai, Tamilnadu 600021, India.
                    </p>

                    <p className="mb-4">
                        These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity (‘you’), and Random Walk, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>

                    <p className="mb-4">
                        Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the ‘Last updated’ date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.
                    </p>

                    <p className="mb-4">
                        All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior to you using the Services.
                    </p>

                    <p className="mb-4">
                        We recommend that you print a copy of these Legal Terms for your records.
                    </p>

                    <h2 className="text-2xl font-semibold mb-2">TABLE OF CONTENTS</h2>
                    <ul className="list-disc pl-6 mb-4">
                        <li><a href="#our-services" className="text-blue-500 underline">OUR SERVICES</a></li>
                        <li><a href="#intellectual-property-rights" className="text-blue-500 underline">INTELLECTUAL PROPERTY RIGHTS</a></li>
                        <li><a href="#user-representations" className="text-blue-500 underline">USER REPRESENTATIONS</a></li>
                        <li><a href="#prohibited-activities" className="text-blue-500 underline">PROHIBITED ACTIVITIES</a></li>
                        <li><a href="#user-generated-contributions" className="text-blue-500 underline">USER GENERATED CONTRIBUTIONS</a></li>
                        <li><a href="#contribution-licence" className="text-blue-500 underline">CONTRIBUTION LICENCE</a></li>
                        <li><a href="#third-party-websites-and-content" className="text-blue-500 underline">THIRD-PARTY WEBSITES AND CONTENT</a></li>
                        <li><a href="#services-management" className="text-blue-500 underline">SERVICES MANAGEMENT</a></li>
                        <li><a href="#privacy-policy" className="text-blue-500 underline">PRIVACY POLICY</a></li>
                        <li><a href="#term-and-termination" className="text-blue-500 underline">TERM AND TERMINATION</a></li>
                        <li><a href="#modifications-and-interruptions" className="text-blue-500 underline">MODIFICATIONS AND INTERRUPTIONS</a></li>
                        <li><a href="#governing-law" className="text-blue-500 underline">GOVERNING LAW</a></li>
                        <li><a href="#dispute-resolution" className="text-blue-500 underline">DISPUTE RESOLUTION</a></li>
                        <li><a href="#corrections" className="text-blue-500 underline">CORRECTIONS</a></li>
                        <li><a href="#disclaimer" className="text-blue-500 underline">DISCLAIMER</a></li>
                        <li><a href="#limitations-of-liability" className="text-blue-500 underline">LIMITATIONS OF LIABILITY</a></li>
                        <li><a href="#indemnification" className="text-blue-500 underline">INDEMNIFICATION</a></li>
                        <li><a href="#user-data" className="text-blue-500 underline">USER DATA</a></li>
                        <li><a href="#electronic-communications-transactions-and-signatures" className="text-blue-500 underline">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</a></li>
                        <li><a href="#california-users-and-residents" className="text-blue-500 underline">CALIFORNIA USERS AND RESIDENTS</a></li>
                        <li><a href="#miscellaneous" className="text-blue-500 underline">MISCELLANEOUS</a></li>
                        <li><a href="#contact-us" className="text-blue-500 underline">CONTACT US</a></li>
                    </ul>

                    <h2 id="our-services" className="text-2xl font-semibold mb-2">OUR SERVICES</h2>
                    <p className="mb-4">
                        The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                    </p>
                    <p className="mb-4">
                        The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                    </p>

                    <h2 id="intellectual-property-rights" className="text-2xl font-semibold mb-2">INTELLECTUAL PROPERTY RIGHTS</h2>
                    <h3 className="text-xl font-semibold mb-2">Our Intellectual Property</h3>
                    <p className="mb-4">
                        We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the ‘Content’), as well as the trademarks, service marks, and logos contained therein (the ‘Marks’).
                    </p>
                    <p className="mb-4">
                        Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.
                    </p>
                    <p className="mb-4">
                        The Content and Marks are provided in or through the Services ‘AS IS’ for your personal, non-commercial use or internal business purpose only.
                    </p>

                    <h3 className="text-xl font-semibold mb-2">Your Use of Our Services</h3>
                    <p className="mb-4">
                        Subject to your compliance with these Legal Terms, including the ‘PROHIBITED ACTIVITIES’ section below, we grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Services and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use or internal business purpose.
                    </p>
                    <p className="mb-4">
                        You agree not to:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Copy, modify, or create derivative works based on the Content;</li>
                        <li>Distribute, transfer, sublicense, lease, lend, or rent the Content;</li>
                        <li>Reverse engineer, decompile, disassemble, or attempt to derive the source code or algorithms of the Content;</li>
                        <li>Use the Content for any commercial purpose or in any manner that competes with our Services.</li>
                    </ul>

                    <h2 id="user-representations" className="text-2xl font-semibold mb-2">USER REPRESENTATIONS</h2>
                    <p className="mb-4">
                        By using the Services, you represent and warrant that:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>You have the legal capacity and you agree to comply with these Legal Terms;</li>
                        <li>You are not a minor in the jurisdiction in which you reside;</li>
                        <li>You will not access the Services through automated or non-human means, such as through a bot, script, or otherwise;</li>
                        <li>You will not use the Services for any illegal or unauthorized purpose;</li>
                        <li>Your use of the Services will not violate any applicable law or regulation.</li>
                    </ul>

                    <h2 id="prohibited-activities" className="text-2xl font-semibold mb-2">PROHIBITED ACTIVITIES</h2>
                    <p className="mb-4">
                        You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
                    </p>
                    <p className="mb-4">
                        As a user of the Services, you agree not to:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us;</li>
                        <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                        <li>Avoid, bypass, disable, or impair any aspects of the Services;</li>
                        <li>Harass, abuse, annoy, threaten, or intimidate any of our employees or agents engaged in providing any portion of the Services to you;</li>
                        <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                    </ul>

                    <h2 id="user-generated-contributions" className="text-2xl font-semibold mb-2">USER GENERATED CONTRIBUTIONS</h2>
                    <p className="mb-4">
                        The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information (collectively, ‘Contributions’). Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated in accordance with our Privacy Policy.
                    </p>
                    <p className="mb-4">
                        By providing any Contributions, you grant us the right and license to use, reproduce, modify, perform, display, and distribute such Contributions to provide the Services and for any other purpose. You represent and warrant that:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>The Contributions are your original work and you have the right to grant us the license described above;</li>
                        <li>Your Contributions do not infringe any third-party intellectual property rights, privacy rights, or any other legal rights;</li>
                        <li>Your Contributions do not violate any applicable laws or regulations.</li>
                    </ul>

                    <h2 id="contribution-licence" className="text-2xl font-semibold mb-2">CONTRIBUTION LICENCE</h2>
                    <p className="mb-4">
                        By submitting Contributions to the Services, you automatically grant us a worldwide, royalty-free, perpetual, non-exclusive, transferable license to use, reproduce, distribute, and publicly display such Contributions. You also waive any moral rights or other rights of attribution or integrity that you may have in your Contributions, to the extent permitted by applicable law.
                    </p>

                    <h2 id="third-party-websites-and-content" className="text-2xl font-semibold mb-2">THIRD-PARTY WEBSITES AND CONTENT</h2>
                    <p className="mb-4">
                        The Services may contain (or you may be sent via the Services) links to other websites (the ‘Third-Party Websites’) as well as articles, photographs, text, graphics, images, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (the ‘Third-Party Content’). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us. We are not responsible for any Third-Party Websites accessed through the Services.
                    </p>
                    <p className="mb-4">
                        If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk and should be aware that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data collection practices, of any site to which you navigate from the Services or relating to any applications you use or install from the site.
                    </p>

                    <h2 id="services-management" className="text-2xl font-semibold mb-2">SERVICES MANAGEMENT</h2>
                    <p className="mb-4">
                        We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; and (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems.
                    </p>
                    <p className="mb-4">
                        We have no obligation to maintain any content or Contributions you make, and we will not be liable for any loss or damage arising from the deletion or loss of any content or Contributions.
                    </p>

                    <h2 id="privacy-policy" className="text-2xl font-semibold mb-2">PRIVACY POLICY</h2>
                    <p className="mb-4">
                        We care about data privacy and security. Please review our Privacy Policy: <a href="/privacy-policy" className="text-blue-500 underline">Privacy Policy</a>. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised that the Services are hosted in India. If you access or use the Services from other jurisdictions, you do so at your own risk.
                    </p>

                    <h2 id="term-and-termination" className="text-2xl font-semibold mb-2">TERM AND TERMINATION</h2>
                    <p className="mb-4">
                        These Legal Terms will remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION.
                    </p>
                    <p className="mb-4">
                        We may terminate your use or participation in the Services or delete your account and any content or information that you posted at any time, without warning, in our sole discretion.
                    </p>

                    <h2 id="modifications-and-interruptions" className="text-2xl font-semibold mb-2">MODIFICATIONS AND INTERRUPTIONS</h2>
                    <p className="mb-4">
                        We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.
                    </p>
                    <p className="mb-4">
                        We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
                    </p>

                    <h2 id="governing-law" className="text-2xl font-semibold mb-2">GOVERNING LAW</h2>
                    <p className="mb-4">
                        These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. Any legal action or proceeding arising out of or relating to these Legal Terms or the Services shall be brought exclusively in the courts located in Chennai, India, and you consent to the jurisdiction of such courts.
                    </p>

                    <h2 id="dispute-resolution" className="text-2xl font-semibold mb-2">DISPUTE RESOLUTION</h2>
                    <p className="mb-4">
                        Any dispute or claim relating in any way to your use of the Services or to any products or services sold or distributed by us will be resolved by binding arbitration, rather than in court. Arbitration under these Legal Terms shall be conducted under the rules of the Indian Arbitration and Conciliation Act.
                    </p>

                    <h2 id="corrections" className="text-2xl font-semibold mb-2">CORRECTIONS</h2>
                    <p className="mb-4">
                        There may be information on the Services that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
                    </p>

                    <h2 id="disclaimer" className="text-2xl font-semibold mb-2">DISCLAIMER</h2>
                    <p className="mb-4">
                        THE SERVICES ARE PROVIDED ON AN ‘AS-IS’ AND ‘AS-AVAILABLE’ BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES AND ALL THIRD-PARTY CONTENT IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                    </p>

                    <h2 id="limitations-of-liability" className="text-2xl font-semibold mb-2">LIMITATIONS OF LIABILITY</h2>
                    <p className="mb-4">
                        IN NO EVENT SHALL WE OR OUR DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, ARISING FROM OR RELATING TO YOUR USE OF THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATING TO THESE LEGAL TERMS OR THE SERVICES SHALL BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE SERVICES.
                    </p>

                    <h2 id="indemnification" className="text-2xl font-semibold mb-2">INDEMNIFICATION</h2>
                    <p className="mb-4">
                        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, directors, agents, contractors, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3) any breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any harmful act toward any other user of the Services with whom you connected via the Services.
                    </p>

                    <h2 id="user-data" className="text-2xl font-semibold mb-2">USER DATA</h2>
                    <p className="mb-4">
                        We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services.
                    </p>

                    <h2 id="electronic-communications-transactions-and-signatures" className="text-2xl font-semibold mb-2">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
                    <p className="mb-4">
                        Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing.
                    </p>
                    <p className="mb-4">
                        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES.
                    </p>

                    <h2 id="california-users-and-residents" className="text-2xl font-semibold mb-2">CALIFORNIA USERS AND RESIDENTS</h2>
                    <p className="mb-4">
                        If any complaint with us cannot be resolved to your satisfaction, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210.
                    </p>

                    <h2 id="miscellaneous" className="text-2xl font-semibold mb-2">MISCELLANEOUS</h2>
                    <p className="mb-4">
                        These Legal Terms and any policies or operating rules posted by us on the Services constitute the entire agreement and understanding between you and us. Our failure to enforce any right or provision of these Legal Terms will not be deemed a waiver of such right or provision.
                    </p>
                    <p className="mb-4">
                        If any provision of these Legal Terms is held to be invalid or unenforceable, such provision will be struck out and the remaining provisions will be enforced. The parties agree that these Legal Terms will not be construed against us by virtue of having drafted them.
                    </p>
                    <p className="mb-4">
                        You agree that these Legal Terms will not be construed against us by virtue of having drafted them.
                    </p>

                    <h2 id="contact-us" className="text-2xl font-semibold mb-2">CONTACT US</h2>
                    <p className="mb-4">
                        For any questions about these Legal Terms, please contact us at <a href="mailto:enquiry@randomwalk.ai" className="text-blue-500 underline">enquiry@randomwalk.ai</a> or by mail to LBR Towers, Anna Salai, Chennai, India, Chennai, Tamilnadu 600021, India.
                    </p>
                </div>
            </div>
        </div>



    );
};

export default Popup;
