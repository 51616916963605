// export default ContactSection1;
import React, { useState,  useMemo, useEffect } from 'react';
import { Client, Databases, ID, Query } from 'appwrite';
import styles from './styles/ContactSection.module.css';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const ContactSection = () => {
  const client = new Client().setEndpoint('https://api.ai-readiness.app/v1').setProject('products-rw');

  const databases = new Databases(client);

  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmailId] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');
  const [errors, setErrors] = useState({});
  const [agreement, setAgreement] = useState(false); // New state for the checkbox
  const [isChecked, setIsChecked] = useState(true);
  const navigate = useNavigate(); // Create a navigate function

  const isValidPhone = useMemo(() => {
    return isValidPhoneNumber("+" + phoneNumber);
  }, [phoneNumber]);

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
  
    // Validate Name
    if (!name) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      newErrors.name = "Name can only contain alphabetic characters and spaces.";
      isValid = false;
    }
  
    // Validate Company Name
    if (!companyName) {
      newErrors.companyName = "Company Name is required.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(companyName)) {
      newErrors.companyName = "Company Name can only contain alphabetic characters and spaces.";
      isValid = false;
    }
  
    // Validate Phone Number
    if (!phoneNumber) {
      newErrors.phoneNumber = "Mobile Number is required.";
      isValid = false;
    } else {
      if (countryCode === "91") {
        const validStartDigits = /^[6789]/;
        if (phoneNumber.length !== 12) {
          newErrors.phoneNumber = "Contact Number for India must be exactly 10 digits.";
          isValid = false;
        } else if (!validStartDigits.test(phoneNumber.slice(-10))) {
          newErrors.phoneNumber = "Contact Number for India must start with 6, 7, 8, or 9.";
          isValid = false;
        } else if (!isValidPhone) {
          newErrors.phoneNumber = "Contact Number is invalid.";
          isValid = false;
        }
      } else if (!isValidPhone) {
        newErrors.phoneNumber = "Contact Number is invalid.";
        isValid = false;
      }
    }
  
    const ignoreMail = ["gmail", "yahoo", "hotmail", "outlook", "rediff"];

    // Validate Email
    if (!email) {
      newErrors.email = "Email Id is required.";
      isValid = false;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      newErrors.email = "Email Id is invalid.";
      isValid = false;
    } else {
      // Extract the domain from the email
      const emailDomain = email.split('@')[1]?.split('.')[0];
    
      // Check if the domain is in the ignoreMail list
      if (ignoreMail.includes(emailDomain)) {
        newErrors.email = "Email Id with this domain is not allowed.";
        isValid = false;
      }
    }
    

    // Set errors and return validation status
    setErrors(newErrors);
    return isValid;
  };
  

  // const handlePhoneNumberChange = (value) => {
  //   if (value) {
  //     const cleanedValue = value.replace(/[^0-9+]/g, '');
  //     setPhoneNumber(cleanedValue);
  //   } else {
  //     setPhoneNumber('');
  //   }
  // };


  const formatPhoneNumber = (phone, dialCode) => {
    return `+${dialCode}-${phone.split(dialCode).join("")}`;
  };
  
  const handlePhoneNumberChange = (phone, country) => {
    // Remove the country dial code and any non-numeric characters from the phone number
    const cleanPhone = phone
      .replace(new RegExp(`^\\+${country.dialCode}`), "")
      .replace(/\D/g, "");
    
    // Update the phone number and country code states
    setPhoneNumber(cleanPhone);
    setCountryCode(country.dialCode);
  };
  
  const handleNameChange = (e) => {
    const value = e.target.value;
    
    // Update the name state
    setName(value);
    
    // Validate the name and update the error state accordingly
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        name: "Name can only contain alphabetic characters and spaces.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, name: "" }));
    }
  };
  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
  
    // Update the company name state
    setCompanyName(value);
  
    // Validate the company name and update the error state accordingly
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        companyName: "Company Name can only contain alphabetic characters and spaces.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, companyName: "" }));
    }
  };
  

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);



  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_COLLECTION_ID,
      );
  
      console.log("Response:", response);
  
      const emailExists = response.documents.some((doc) => doc.email === email);
  
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber, countryCode);
      const phoneExists = response.documents.some(
        (doc) => doc.phoneNumber.slice(-10) === formattedPhoneNumber.slice(-10)
      );
  
      if (emailExists || phoneExists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: emailExists ? "Email already exists." : "",
          phoneNumber: phoneExists ? "Phone number already exists." : "",
        }));
        return;
      }
  
      await databases.createDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_COLLECTION_ID,
        ID.unique(),
        {
          name,
          companyName,
          phoneNumber: formattedPhoneNumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
  
      setName('');
      setCompanyName('');
      setPhoneNumber('');
      setEmailId('');
      setAgreement(true); // Reset the checkbox
      setErrors({});
      navigate('/thankyou');
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };
  


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleLinkClick = (e) => {
    e.preventDefault();
    setIsPopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const [isPopup2Open, setIsPopup2Open] = useState(false);
  const handleLinkClick2 = (e) => {
    e.preventDefault();
    setIsPopup2Open(true);
  };
  const handleClosePopup2 = () => {
    setIsPopup2Open(false);
  };



  return (
    <section className={styles.contactSection}>
      <div className={styles.content}>
        <h1 className={styles.heading}>
          <div className={styles['gradient-text4']}>Kick Start Your AI Journey</div>
        </h1>
        <h2 className={styles.heading2}>Stop Guessing, Start Knowing!</h2>
        <p className={styles.description}>AI Readiness & Digital Maturity Index</p>
        <p className={styles.bridge}>The Bridge You Need to Turn AI Ambition into Strategy.</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles['input-container']}>
          <label htmlFor='name' className={styles.placeholder}>
            Name
          </label>
          <input
            type='text'
            id='name'
            className={styles.input}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <p className={styles.error}>{errors.name}</p>}

        </div>
        
        <div className={styles['input-container']}>
          <label htmlFor='companyName' className={styles.placeholder}>
            Company Name
          </label>
          <input
            type='text'
            id='companyName'
            className={styles.input}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          {errors.companyName && <p className={styles.error}>{errors.companyName}</p>}
        </div>
        

        <div className={styles['input-container']}>
          <label htmlFor='phoneNumber' className={styles.placeholder}>
            Contact Number
          </label>
          <PhoneInput
            country={"in"}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            buttonStyle={{ backgroundColor: "rgba(255,255,255,0.2)", }}
            inputStyle={{
              width: "100%",
              borderRadius: "3px",
              fontWeight: "500",
              fontFamily: "inherit",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
            }}
            dropdownStyle={{
              width: "670%",
              height: "450%",
              borderRadius: "5px",
              color: "black",
              textAlign: "left",
              zIndex: 999,
            }}
          />
          {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
        </div>

        <div className={styles['input-container']}>
          <label htmlFor='email' className={styles.placeholder}>
          Business/Company Email
          </label>
          <input
            type='email'
            id='email'
            className={styles.input}
            value={email}
            onChange={(e) => setEmailId(e.target.value)}
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </div>

        <button
          type="submit"
          disabled={!isChecked}
          className={`${styles.button} ${!isChecked ? 'bg-gray-300 text-gray-700 cursor-not-allowed' : 'bg-[#C62B6D] text-white'} ${!isChecked ? 'hover:bg-gray-400' : 'hover:bg-[#C62B6D]'
            }`}
        >
          Take the Assessment Now
          <span className={styles.arrow}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              className={`${styles.arrowIcon}`}
            >
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='4' d='M2 12h20m-7-7 7 7-7 7' />
            </svg>
          </span>
        </button>
      </form>
    </section>
  );
};

export default ContactSection;
